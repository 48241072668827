// strona listy produktów

import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Sliders, X } from 'react-bootstrap-icons';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import find from 'lodash/find';
import { ChevronLeft } from 'react-bootstrap-icons';

import { reduxActions, useDispatch, useSelector } from 'store';
import {
  useGetProductsInfinitiveList,
  useGetProductsFiltersMain,
  useGetProductsSortMethods,
  useGetProductsTitle,
  useGetProductsBreadcrumbs,
  useGetCmsSectionArticle
} from 'api';
import { IProductsRequest, IProductsSortMethod, IProductListItem } from 'api/types';
import { useAppNavigate, useRWD, useScrollDown, usePrevious, useEffectAfterMount } from 'hooks';
import { Categories, MainFilters } from './components';
import { MobileProductItem, ProductItem } from 'components/containers';
import {
  Button,
  Breadcrumbs,
  Container,
  Loader,
  Select,
  ProgressBar,
  Modal
} from 'components/controls';
import GridSwitcher from 'components/controls/GridSwitcher';

import styles from 'theme/pages/Products/Products.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  categoryId?: string;
  mode?: 'PROMOTIONS' | 'NEWS' | 'BESTSELLERS';
}

const Products: FC<IProps> = ({ categoryId, mode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const location = useLocation();

  const { category_id } = qs.parse(location.search);

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = location.pathname.slice(0, getPosition(location.pathname, '/', 2));

  const { isMobile } = useRWD();

  const { isScrollDown } = useScrollDown();

  // globalnie ustawiona kategoria i fraza wyszukiwarki
  const {
    categoryId: globalCategoryId,
    searchKeyword: globalSearchKeyword,
    gridType
  } = useSelector((state) => state.products);

  // kategoria pochodząca z url resolvera
  const urlResolverCategoryId = parseInt(categoryId || '0');

  const categoryIdQueryParam = urlResolverCategoryId
    ? { category_id: urlResolverCategoryId }
    : typeof category_id === 'string'
    ? { category_id: parseInt(category_id) }
    : {};

  // referencja dla komponenyu paginacji
  const paginationRef = useRef<HTMLInputElement>(null);

  // Aktualne filtry
  const [queryFilters, setQueryFilters] = useState<IFilter[]>([]);

  const [isFilterMobile, setIsFilterMobile] = useState(false);

  // poprzednia wartość kategorii
  const prevSearchKeyword = usePrevious(globalSearchKeyword);

  // poprzednia wartość kategorii
  const prevCategoryId = usePrevious(categoryIdQueryParam.category_id);

  // Parametry zapytania do API
  const [productsQuery, setProductsQuery] = useState<IProductsRequest>({
    limit: 12,
    search_keyword: '',
    filter_attributes: '',
    sort_method: '',
    mode: mode,
    ...categoryIdQueryParam,
    ...qs.parseUrl(window.location.href, { parseNumbers: true }).query,
    page: 1
  });

  // ustawienie flagi czy należy wykonać zapytanie o kolejną stronę
  const [isPaginationOnScreen, setIsPaginationOnScreen] = useState(false);

  // pobranie listy filtrów
  const { data: mainFiltersData, isFetching: isFiltersLoading } = useGetProductsFiltersMain(
    {
      ...productsQuery,
      page: 1,
      limit: 999
    },
    { keepPreviousData: true }
  );

  // ustawienie pierwszej strony po powrocie z karty produktu
  useEffect(() => {
    if (localStorage.getItem('PRODUCT_ID')) {
      setProductsQuery((prevState) => ({
        ...prevState,
        page: 1
      }));
    }
  }, []);

  // Pobranie listy produktów
  const {
    data: productsData,
    status: productsStatus,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    isPreviousData
  } = useGetProductsInfinitiveList(
    {
      ...productsQuery,
      // ustawienie pierwszej strony po powrocie z karty produktu
      page: localStorage.getItem('PRODUCT_ID') ? 1 : productsQuery.page
    },
    {
      keepPreviousData: true,
      onSuccess: () => {
        if (localStorage.getItem('PRODUCT_ID')) {
          localStorage.removeItem('PRODUCT_ID');
        }
      }
    }
  );

  // Pobranie nagłówka
  const { data: productsTitleData, refetch: refetchProductsTitleData } = useGetProductsTitle(
    productsQuery,
    { enabled: false, keepPreviousData: true }
  );

  // Pobieranie breadcrumbs
  const {
    data: productsBreadcrumbsData,
    refetch: refetchProductsBreadcrumbsData,
    isLoading: isBreadCrumbsLoading
  } = useGetProductsBreadcrumbs(productsQuery, { enabled: false, keepPreviousData: true });

  // Pobranie opcji sortowania
  const { data: productsSortingMethodsData } = useGetProductsSortMethods({
    page: 1,
    limit: 999
  });

  // pobieranie danych tylko przy zmianie kategorii i frazy wyszukiwania
  useEffect(() => {
    refetchProductsTitleData();
    refetchProductsBreadcrumbsData();
  }, [
    productsQuery.category_id,
    productsQuery.search_keyword,
    productsQuery.mode,
    productsQuery.filter_attributes
  ]);

  // pobieranie opisów seo
  const { data: seoDescriptionData, refetch: refetchSeoDescription } = useGetCmsSectionArticle(
    'CATEGORY_DESCRIPTION',
    categoryIdQueryParam.category_id?.toString() || '',
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (categoryIdQueryParam.category_id) {
      refetchSeoDescription();
    }
  }, [categoryIdQueryParam.category_id]);

  // Zmiana url'a przy zmianie parametrów zapytania do API
  useEffect(() => {
    const { mode, category_id, ...restQuery } = productsQuery;

    const navigationUrl = categoryId
      ? `${location.pathname.replace(urlPrefix, '')}?${qs.stringify(restQuery, {
          skipEmptyString: true
        })}`
      : `${location.pathname.replace(urlPrefix, '')}?${qs.stringify(
          { ...restQuery, category_id },
          {
            skipEmptyString: true
          }
        )}`;

    navigate(navigationUrl, { replace: true });
  }, [
    productsQuery.page,
    productsQuery.filter_attributes,
    productsQuery.sort_method,
    productsQuery.limit
  ]);

  // zerowanie filtrów przy zmianie frazy wyszukiwania
  useEffect(() => {
    if (globalSearchKeyword !== prevSearchKeyword && globalSearchKeyword && prevSearchKeyword) {
      setProductsQuery({
        ...productsQuery,
        filter_attributes: ''
      });
    }
  }, [globalSearchKeyword, prevSearchKeyword]);

  // Ustawienie aktywnych filtrów z url'a (podczas wejścia na stronę) i następnie podczas wybierania nowych filtrów
  useEffect(() => {
    typeof productsQuery.category_id !== 'undefined' &&
      typeof productsQuery.search_keyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(productsQuery.search_keyword));
    setQueryFilters(
      productsQuery.filter_attributes
        ?.split('|')
        .filter((item) => item)
        .map((queryFilter) => {
          const queryFilterArray = queryFilter.split('=');
          return {
            filter_id: queryFilterArray[0],
            filter_value: queryFilterArray[1]
          };
        }) || []
    );
  }, [productsQuery, categoryIdQueryParam.category_id]);

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs(
        productsBreadcrumbsData
          ? productsBreadcrumbsData.items.map((item) => ({
              name: item.name,
              path: item.category_id
                ? `/products?category_id=${item.category_id}&search_keyword=`
                : undefined
            }))
          : []
      )
    );
  }, [productsBreadcrumbsData?.items]);

  // resetowanie filtrów po zmianie kategorii
  useEffectAfterMount(() => {
    if (categoryIdQueryParam.category_id !== prevCategoryId && prevCategoryId) {
      setProductsQuery((prevState) => ({
        ...prevState,
        filter_attributes: ''
      }));
    }
  }, [categoryIdQueryParam.category_id]);

  // aktualizacja parametrów zapytania przy zmianie kategorii
  useEffectAfterMount(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      mode: mode,
      category_id: categoryIdQueryParam.category_id
    }));
  }, [categoryIdQueryParam.category_id]);

  // aktualizacja parametrów zapytania przy zmianie frazy wyszukiwania
  useEffectAfterMount(() => {
    if (globalSearchKeyword !== prevSearchKeyword && prevSearchKeyword) {
      setProductsQuery((prevState) => ({
        ...prevState,
        filter_attributes: '',
        search_keyword: globalSearchKeyword
      }));
    }
  }, [globalSearchKeyword]);

  const urlSearchKeyword = qs.parseUrl(location.search).query.search_keyword;
  // Uaktualnienie frazy wyszukwania (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    typeof urlSearchKeyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(urlSearchKeyword));
  }, [urlSearchKeyword]);

  // Przygotowanie listy produktów do wyświetlenia
  const itemsList = useMemo(() => {
    const list: IProductListItem[] = [];

    productsData?.pages.forEach((page) => {
      list.push(...page.items.map((item) => ({ ...item, page: page.page })));
    });

    return list;
  }, [productsData, productsQuery]);

  // liczba produktów aktualnie na stronie
  const itemsCount: number = itemsList.length;

  // 'offset' dla doładowania kolejnych produktów
  const productsOffset = 6000;

  // całkowita liczba produktów
  const totalCount: number = productsData
    ? productsData.pages[productsData.pages.length - 1].total_count
    : 0;

  const hasNextPage = itemsCount < totalCount;

  useEffect(() => {
    // ustawienie paginacji SEO
    let seoPagination = productsQuery.page || 1;

    const handleScroll = () => {
      const { current } = paginationRef;
      if (current) {
        const { y } = current.getBoundingClientRect();
        if (y - productsOffset < 0 && !isPaginationOnScreen) {
          setIsPaginationOnScreen(true);
        }
      }

      const nextElement = document.querySelector(`.productCardWrapper-${seoPagination}`);
      const prevElement = document.querySelector(`.productCardWrapper-${seoPagination - 1}`);

      const topNextElement = nextElement?.getBoundingClientRect().top;
      const topPrevElement = prevElement?.getBoundingClientRect().top;

      if (topNextElement && topNextElement < 0) {
        navigate(
          `${window.location.pathname.replace(urlPrefix, '')}?${qs.stringify(
            { ...qs.parse(window.location.search), page: seoPagination + 1 },
            { skipEmptyString: true }
          )}`,
          {
            replace: true
          }
        );

        seoPagination = seoPagination + 1;
      }

      if (topPrevElement && topPrevElement > 0) {
        navigate(
          `${window.location.pathname.replace(urlPrefix, '')}?${qs.stringify(
            { ...qs.parse(window.location.search), page: seoPagination - 1 },
            { skipEmptyString: true }
          )}`,
          {
            replace: true
          }
        );

        seoPagination = seoPagination - 1;
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => document.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isPaginationOnScreen && hasNextPage) {
      fetchNextPage().then(() => {
        setIsPaginationOnScreen(false);
      });
    }
  }, [isPaginationOnScreen, hasNextPage, itemsCount]);

  // Funkcja aktualizująa filtry (w stanie komponentu i zapytaniu do API)
  const updateQueryFilters = (filters: IFilter[], currentFilter?: string) => {
    setProductsQuery((prevState) => {
      return {
        ...prevState,
        filter_attributes: filters
          .map((filter) => {
            const filterAttributes = qs.parse(
              prevState.filter_attributes?.replaceAll('|', '&') || ''
            );
            if (filter.filter_type === 'multichoice' && filterAttributes[filter.filter_id]) {
              if (currentFilter !== filter.filter_id) {
                return `${filter.filter_id}=${filterAttributes[filter.filter_id]}`;
              }

              return `${filter.filter_id}=${filterAttributes[filter.filter_id]};${
                filter.filter_value
              }`;
            }

            return `${filter.filter_id}=${filter.filter_value}`;
          })
          .join('|'),
        page: 1
      };
    });
  };

  const clearFilters = (filter: string) => {
    if (!filter) {
      setProductsQuery((prevState) => {
        return {
          ...prevState,
          search_keyword: '',
          filter_attributes: '',
          sort_method: '',
          page: 1
        };
      });

      return;
    }

    const filterAttributes = qs.parse(productsQuery.filter_attributes?.replaceAll('|', '&') || '');
    const filters = mapValues(filterAttributes, (attribute: string) =>
      attribute.replace(`;${filter}`, '').replace(`${filter};`, '').replace(filter, '')
    );

    setProductsQuery((prevState) => {
      return {
        ...prevState,
        filter_attributes: qs
          .stringify(
            omitBy(filters, (o) => !o),
            { encode: false }
          )
          .replaceAll('&', '|'),
        page: 1
      };
    });
  };

  const renderSelectedFilter = (filter: IFilter) => {
    const selectedFilter = mainFiltersData?.items.find((o) => o.id === filter.filter_id);
    return (
      <div key={filter.filter_id}>
        <div className={styles.filterType}>{selectedFilter?.label}</div>
        {filter.filter_id === 'PRICE' ? (
          <div className={styles.selectedFilter}>
            <X onClick={() => clearFilters(filter.filter_value)} />
            <span>{filter.filter_value.replace(';', ' - ')}</span>
          </div>
        ) : (
          filter.filter_value.split(';').map((value) => (
            <div className={styles.selectedFilter} key={value}>
              <X onClick={() => clearFilters(value)} />
              <span>{find(selectedFilter?.values, { value })?.name}</span>
            </div>
          ))
        )}
      </div>
    );
  };

  const renderFiltersContent = () => (
    <div className={classnames(styles.filters, { [styles.isFiltersLoading]: !!isFiltersLoading })}>
      {mainFiltersData?.items && !!queryFilters.length && (
        <div className={styles.selectedFilters}>
          {isMobile && (
            <p className={styles.filterModalSelectedTitle}>
              <Trans>Twój wybór</Trans>
            </p>
          )}
          {queryFilters.map((filter) => renderSelectedFilter(filter))}
          <button className={styles.clearAllFilters} onClick={() => clearFilters('')}>
            <Trans>Wyczyść filtry</Trans>
          </button>
        </div>
      )}
      <Categories
        searchKeyword={globalSearchKeyword}
        onCategoryClick={(cat) => {
          dispatch(reduxActions.setCategoryId(cat.id === globalCategoryId ? undefined : cat.id));
          navigate(
            `/products?category_id=${cat.id}&filter_attributes=${productsQuery.filter_attributes}`
          );
        }}
        chosenCategoryId={globalCategoryId || undefined}
        productsQueryParams={productsQuery}
      />

      <MainFilters
        onChange={updateQueryFilters}
        queryFilters={queryFilters}
        filtersData={mainFiltersData?.items}
        clearFilters={clearFilters}
        categoryId={globalCategoryId}
        searchKeywords={globalSearchKeyword}
      />
    </div>
  );

  const getActiveFiltersCount = (
    queryFilters: Array<{ filter_value: string; filter_id: string }>
  ) => {
    if (!queryFilters || queryFilters.length === 0) {
      return null;
    }

    const totalCount = queryFilters.reduce((sum, filter) => {
      const count = filter.filter_value.split(';').length;
      return sum + count;
    }, 0);

    return totalCount;
  };

  const ActiveFiltersCount = () => {
    const activeFilters = getActiveFiltersCount(queryFilters);

    if (!activeFilters) {
      return null;
    }

    return <div className={styles.filterBox}>{activeFilters}</div>;
  };

  const isLoading =
    !productsData ||
    (isRefetching && !isFetchingNextPage && !localStorage.getItem('PRODUCT_ID') && isPreviousData);

  const renderProductsPlaceholders = () => {
    return Array.from(Array(12).keys()).map((i) => (
      <Grid
        key={i}
        item
        xs={12}
        sm={gridType === 'line' ? 12 : 6}
        lg={gridType === 'line' ? 12 : 4}
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem">
        <div className={styles.productPlaceholder}>
          <div className={styles.productPlaceholderImage} />
          <div className={styles.productPlaceholderProducer} />
          <div className={styles.productPlaceholderName} />
          <div className={styles.productPlaceholderPrice} />
        </div>
      </Grid>
    ));
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Products')}>
      {productsTitleData && (
        <Helmet>
          <title>{productsTitleData.name} - VMP</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}

      <Breadcrumbs isLoading={isBreadCrumbsLoading} />
      {isFilterMobile ? (
        isMobile && (
          <Modal
            fullScreen={true}
            isFiltersOverlay={true}
            title={
              <div className={styles.filterModalTitle} onClick={() => setIsFilterMobile(false)}>
                <ChevronLeft />
                <Trans>Wróć</Trans>
              </div>
            }>
            <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Products')}>
              <div className={styles.content}>{renderFiltersContent()}</div>
            </div>
          </Modal>
        )
      ) : (
        <Container>
          <div className={styles.title}>
            {productsTitleData ? (
              <h1>{productsTitleData.name}</h1>
            ) : (
              <span className={styles.titlePlaceholder} />
            )}
            <GridSwitcher type={gridType} />
          </div>
          {seoDescriptionData?.article_fields?.[0]?.value && (
            <h2
              className={styles.seoBlock}
              dangerouslySetInnerHTML={{
                __html: seoDescriptionData?.article_fields?.[0].value
              }}
            />
          )}
          <div className={styles.content}>
            {!isMobile && renderFiltersContent()}
            <div className={styles.list}>
              {isMobile && (
                <div
                  className={classnames(styles.bottomBar, {
                    [styles.isScrollDown]: !isScrollDown
                  })}>
                  <div className={styles.filterWrapper}>
                    <div onClick={() => setIsFilterMobile(true)} className={styles.filterButton}>
                      <Sliders />
                      <Trans>Filtry</Trans>
                      <ActiveFiltersCount />
                    </div>
                    <div className={styles.sortingSelectWrapper}>
                      <Select<IProductsSortMethod>
                        onChange={(sortMethod) => {
                          sortMethod &&
                            setProductsQuery({
                              ...productsQuery,
                              sort_method: sortMethod.id,
                              page: 1
                            });
                        }}
                        value={productsQuery.sort_method}
                        options={
                          productsSortingMethodsData?.items.map((item) => ({
                            value: item.id,
                            label: item.name,
                            item
                          })) || []
                        }
                        placeholder={t('Sortowanie')}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isMobile && (
                <div className={styles.actionsTopBar}>
                  <span>
                    <Trans>Liczba produktów</Trans>&#58;&nbsp;
                    {productsTitleData?.products_count}
                  </span>
                </div>
              )}

              {!isMobile && (
                <Grid item sm={12}>
                  <div className={styles.actionsTopBar}>
                    <span>
                      <Trans>Liczba produktów</Trans>&#58;&nbsp;
                      {productsTitleData?.products_count}
                    </span>
                    <div>
                      <div className={styles.sortingSelectWrapper}>
                        <Select<IProductsSortMethod>
                          onChange={(sortMethod) => {
                            sortMethod &&
                              setProductsQuery({
                                ...productsQuery,
                                sort_method: sortMethod.id,
                                page: 1
                              });
                          }}
                          value={productsQuery.sort_method}
                          options={
                            productsSortingMethodsData?.items.map((item) => ({
                              value: item.id,
                              label: item.name,
                              item
                            })) || []
                          }
                          placeholder={t('Sortowanie')}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
              {isLoading && <Grid item sm={12} className={styles.loadingWrapper}></Grid>}
              <Grid
                container
                columnSpacing="3px"
                rowSpacing="24px"
                itemScope
                itemType="http://schema.org/ItemList">
                {itemsList.length === 0
                  ? renderProductsPlaceholders()
                  : itemsList.map((product, i) => (
                      <Grid
                        className={`productCardWrapper-${product.page}`}
                        key={product.id}
                        item
                        xs={12}
                        sm={gridType === 'line' ? 12 : 6}
                        lg={gridType === 'line' ? 12 : 4}
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem">
                        {isMobile ? (
                          <MobileProductItem
                            product={product}
                            line={gridType === 'line'}
                            categoryId={globalCategoryId}
                            searchKeywords={globalSearchKeyword}
                          />
                        ) : (
                          <ProductItem
                            product={product}
                            line={gridType === 'line'}
                            withDynamicGallery
                            categoryId={globalCategoryId}
                            searchKeywords={globalSearchKeyword}
                            mode={productsQuery.mode}
                          />
                        )}
                        <meta itemProp="position" content={String(i + 1)} />
                      </Grid>
                    ))}
                <div ref={paginationRef} className={classnames(styles.paginationWrapper)}>
                  {productsStatus === 'success' && hasNextPage && (
                    <>
                      {productsData && (
                        <span className={classnames(styles.paginationCount)}>
                          {itemsCount} <Trans>na</Trans> {totalCount} <Trans>produktów</Trans>
                        </span>
                      )}
                      <ProgressBar value={(itemsCount / totalCount) * 100} />
                      <Button
                        disabled={isFetchingNextPage}
                        ghost
                        square
                        onClick={() => fetchNextPage()}>
                        <Trans>Więcej produktów</Trans>
                        {isFetchingNextPage && (
                          <div className={styles.loaderWrapper}>
                            <Loader />
                          </div>
                        )}
                      </Button>
                    </>
                  )}
                </div>
                {seoDescriptionData?.article_fields?.[1]?.value && (
                  <h2
                    className={styles.seoBlock}
                    dangerouslySetInnerHTML={{
                      __html: seoDescriptionData?.article_fields?.[1].value
                    }}
                  />
                )}
              </Grid>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Products;
